import bezier from './bezier'

const EPSILON = 1e-9
const easeOut = bezier(0, 0, 0.48, 1, EPSILON)

let activeItem = 0
let textRows
let carousel
let textCarousel
let videoElements
let nextTimeout
let currentCarouselScroll = 0
let currentTextCarouselScroll = 0

const video1 = require('../assets/img/1_map.mp4')
const video2 = require('../assets/img/2_checkin.mp4')
const video3 = require('../assets/img/3_emoji.mp4')
const video4 = require('../assets/img/4_speed.mp4')

const videoUrls = [video1, video2, video3, video4, video1]

function main() {
  carousel = document.getElementById('video-carousel')
  textCarousel = document.getElementById('text-carousel')
  textRows = document.querySelectorAll('.content__text-row')
  videoElements = document.getElementsByTagName('video')

  for (let i = 0; i < textRows.length; i++) {
    textRows[i].onclick = onTextClick
  }

  for (let i = 0; i < videoElements.length; i++) {
    if (i > 0 && i < 4) {
      videoElements[i].setAttribute('src', videoUrls[i])
    }
    // console.log(i, videoElements[i].readyState)
    // videoElements[i].addEventListener('canplaythrough', event => {
    //   console.log(i, event)
    //   if (videoElements[i].paused) {
    //     videoElements[i].play()
    //     requestAnimationFrame(() => {
    //       videoElements[i].pause()
    //     })
    //   }
    // })
    // if (videoElements[i].readyState)
    //   videoElements[i].on = e => {
    //     console.log(e)
    //   }
  }

  videoElements[4].addEventListener('canplay', () => {
    if (!videoElements[4].paused) {
      videoElements[4].pause()
      videoElements[4].currentTime = 0
    }
  })

  document.getElementById('video-prev').onclick = async () => {
    if (isTextClickInWork) return
    isTextClickInWork = true

    let toId = activeItem + -1
    if (toId < 0) {
      toId = 3
    }

    await changeActiveText(toId)

    isTextClickInWork = false
  }
  document.getElementById('video-next').onclick = async () => {
    if (isTextClickInWork) return
    isTextClickInWork = true

    let toId = activeItem + 1
    if (toId > 3) {
      toId = 0
    }

    await changeActiveText(toId)

    isTextClickInWork = false
  }

  const scheduleSlide = () => {
    const duration = videoElements[0].duration
    if (!duration) {
      setTimeout(scheduleSlide, 64)
      return
    }

    nextTimeout = setTimeout(goNextVideo, videoElements[0].duration * 1000 - 16)
  }

  // setInterval(() => {
  //   let toId = activeItem + 1
  //   if (toId > 3) {
  //     toId = 0
  //   }

  //   changeActiveText(toId)
  // }, 500)
}

let isTextClickInWork = false
async function onTextClick(event) {
  if (isTextClickInWork) return
  isTextClickInWork = true

  const toId = parseInt(event.target.getAttribute('data-row-id'))

  await changeActiveText(toId)

  isTextClickInWork = false
}

async function goNextVideo() {
  // if (isTextClickInWork) return
  // isTextClickInWork = true

  let toId = activeItem + 1
  if (toId > 3) {
    toId = 0
  }
  await changeActiveText(toId)

  // isTextClickInWork = false
}

function changeActiveText(toId) {
  return new Promise(resolve => {
    if (nextTimeout) {
      clearTimeout(nextTimeout)
      nextTimeout = 0
    }
    textRows[activeItem].classList.remove('active')
    textRows[toId].classList.add('active')

    videoElements[activeItem].pause()

    const videoWidth = carousel.clientWidth / 5
    const oldTime = videoElements[activeItem].currentTime
    const needToRotate =
      (activeItem === 3 && toId === 0) || (activeItem === 0 && toId === 3)
    const fromId = activeItem

    activeItem = toId

    if (!videoElements[toId].paused) {
      videoElements[toId].pause()
    }
    videoElements[toId].currentTime = 0

    if (needToRotate) {
      // videoElements[4].currentTime = oldTime
      if (toId === 0) {
        toId = 4
      } else if (toId === 3) {
        currentCarouselScroll = videoWidth * 4
        currentTextCarouselScroll = 320 * 4

        carousel.style.transform = `translateX(-${videoWidth * 4}px)` //
        textCarousel.style.transform = `translateX(-${320 * 4}px)`
      }
    }

    let num = 30
    let i = 0

    const oldScroll = currentCarouselScroll //carousel.scrollLeft
    const newScroll = videoWidth * toId
    const diff = newScroll - oldScroll

    const textOldScroll = currentTextCarouselScroll //textCarousel.scrollLeft
    const textNewScroll = 320 * toId
    const textdiff = textNewScroll - textOldScroll

    const updateScroll = () => {
      const easeCoff = easeOut(i / num)
      const carouselScroll = oldScroll + diff * easeCoff
      const textScroll = textOldScroll + textdiff * easeCoff

      carousel.style.transform = `translateX(-${carouselScroll}px)`
      textCarousel.style.transform = `translateX(-${textScroll}px)`

      if (i < num) {
        i++
        requestAnimationFrame(updateScroll)
      } else {
        if (needToRotate && toId === 4) {
          carousel.style.transform = `translateX(0px)`
          textCarousel.style.transform = `translateX(0px)`
          currentCarouselScroll = 0
          currentTextCarouselScroll = 0
        } else {
          currentCarouselScroll = carouselScroll
          currentTextCarouselScroll = textScroll
        }

        videoElements[fromId].currentTime = 0
        videoElements[activeItem].play()

        nextTimeout = setTimeout(
          goNextVideo,
          videoElements[activeItem].duration * 1000 - 16,
        )
        resolve()
      }
    }

    requestAnimationFrame(updateScroll)
  })
}

window.onload = main
